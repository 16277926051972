import { render, staticRenderFns } from "./resourceView-copy.vue?vue&type=template&id=fb682c96&scoped=true&"
import script from "./resourceView-copy.vue?vue&type=script&lang=ts&"
export * from "./resourceView-copy.vue?vue&type=script&lang=ts&"
import style0 from "./resourceView-copy.vue?vue&type=style&index=0&id=fb682c96&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb682c96",
  null
  
)

export default component.exports